<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="cp_area">Area Sampling</label>
                <InputText id="cp_area" v-model="forms.cp_area" required="true" autofocus :class="{ 'p-invalid': errorAdd.cp_area }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.cp_area" >{{ errorAdd.cp_area[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="cp_alamat">Alamat</label>
                <InputText id="cp_alamat" v-model="forms.cp_alamat" required="true" autofocus :class="{ 'p-invalid': errorAdd.cp_alamat }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.cp_alamat" >{{ errorAdd.cp_alamat[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="eo">EO</label>
                <Dropdown id="eo_id" dataKey="eo_id" v-model="forms.eo_id" :loading="loadingDropdownEO"
                    :options="dataDropdownEO" :class="{ 'p-invalid': errorAdd.eo_id }"
                    optionLabel="label" optionValue="eo_id" placeholder="Pilih EO"
                    :filter="true" :showClear="true" @filter="searchDropdownEO($event, 'add')"
                    @change="changeProvinsi()"
                />
                <small class="p-invalid" style="color: red" v-if="errorAdd.eo_id" >{{ errorAdd.eo_id[0] }}</small>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Provinsi</label>
                <Dropdown id="provinsi_id" dataKey="provinsi_id" v-model="forms.provinsi_id" :loading="loadingDropdownProvinsi"
                    :options="dataDropdownProvinsi" :class="{ 'p-invalid': errorAdd.provinsi_id }"
                    optionLabel="provinsi_name" optionValue="provinsi_id" placeholder="Pilih Provinsi"
                    :filter="true" :showClear="true" @filter="searchDropdownProvinsi($event, 'add')"
                    @change="changeKabupaten()" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.provinsi_id">{{
                    errorAdd.provinsi_id[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Kabupaten</label>
                <Dropdown id="kabupaten_id" dataKey="kabupaten_id" v-model="forms.kabupaten_id" :loading="loadingDropdownKabupaten"
                    :options="dataDropdownKabupaten" :class="{ 'p-invalid': errorAdd.kabupaten_id }"
                    optionLabel="kabupaten_name" optionValue="kabupaten_id" placeholder="Pilih Kabupaten"
                    :filter="true" :showClear="true" @filter="searchDropdownKabupaten($event, 'add')"
                    @change="changeKecamatan()"/>
                <small class="p-invalid" style="color: red" v-if="errorAdd.kabupaten_id">{{
                    errorAdd.kabupaten_id[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Kecamatan</label>
                <Dropdown id="kecamatan_id" dataKey="kecamatan_id" v-model="forms.kecamatan_id" :loading="loadingDropdownKecamatan"
                    :options="dataDropdownKecamatan" :class="{ 'p-invalid': errorAdd.kecamatan_id }"
                    optionLabel="kecamatan_name" optionValue="kecamatan_id" placeholder="Pilih Kecamatan"
                    :filter="true" :showClear="true" @filter="searchDropdownKecamatan($event, 'add')"
                    @change="changeKelurahan()"/>
                <small class="p-invalid" style="color: red" v-if="errorAdd.kecamatan_id">{{
                    errorAdd.kecamatan_id[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Kelurahan</label>
                <Dropdown id="kelurahan_id" dataKey="kelurahan_id" v-model="forms.kelurahan_id" :loading="loadingDropdownKelurahan"
                    :options="dataDropdownKelurahan" :class="{ 'p-invalid': errorAdd.kelurahan_id }"
                    optionLabel="kelurahan_name" optionValue="kelurahan_id" placeholder="Pilih Kelurahan"
                    :filter="true" :showClear="true" @filter="searchDropdownKelurahan($event, 'add')"/>
                <small class="p-invalid" style="color: red" v-if="errorAdd.kelurahan_id">{{
                    errorAdd.kelurahan_id[0]
                }}</small>
            </div>
        </div>
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div>                            
                <Button :loading="loading" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
            </div>
        </div>
    </div>
</template>
<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data(){
        return{
            // loading
            loading: false,
            loadingDropdownProvinsi: false,
            loadingDropdownKabupaten: false,
            loadingDropdownKecamatan: false,
            loadingDropdownKelurahan: false,
            loadingDropdownEO: false,

            // dataDropdown,
            dataDropdownProvinsi: null,
            dataDropdownKabupaten: null,
            dataDropdownKelurahan: null,
            dataDropdownKecamatan: null,
            dataDropdownEO: null,

            // addNew
            forms: {
                cp_alamat: null,
                cp_area: null,
                eo_id: null,
                provinsi_id: null,
                kabupaten_id: null,
                kecamatan_id: null,
                kelurahan_id: null,
            },
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownEO('');
    },
    created(){
    },
    computed:{
        ...mapGetters(['errorAdd']),
    },
    methods: {
        // DROPDOWN
        searchDropdownEO(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.deo.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownEO = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/eo',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "add") {
                            this.dataDropdownEO = res.data.data;
                            this.loadingDropdownEO = false;
                        } else if (purpose == null) {
                            this.dataDropdownEO = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        changeProvinsi() {
            this.forms.provinsi_id = null;
            this.dataDropdownProvinsi = null;
            this.searchDropdownProvinsi('');

            this.changeKabupaten();
        },
        searchDropdownProvinsi(event, purpose, valueEdit) {
            setTimeout(() => {

                if (this.forms.eo_id) {

                    if (valueEdit) {
                        this.$refs.dprov.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownProvinsi = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/provinsi-v3',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "eo_id": this.forms.eo_id,
                        }
                    })
                    .then(res => {

                        if (purpose == "add") {
                            this.dataDropdownProvinsi = res.data.data;
                            this.loadingDropdownProvinsi = false;
                        } else if (purpose == null) {
                            this.dataDropdownProvinsi = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }

            }, 250);
        },
        changeKabupaten() {
            this.forms.kabupaten_id = null;
            this.dataDropdownKabupaten = null;
            this.searchDropdownKabupaten('');

            this.changeKecamatan();
        },
        searchDropdownKabupaten(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.forms.provinsi_id) {

                    if (valueEdit) {
                        this.$refs.dkab.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownKabupaten = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/kabupaten-v3',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "provinsi_id": this.forms.provinsi_id,
                            "eo_id": this.forms.eo_id,
                        }
                    })
                        .then(res => {

                            if (purpose == "add") {
                                this.dataDropdownKabupaten = res.data.data;
                                this.loadingDropdownKabupaten = false;
                            } else if (purpose == null) {
                                this.dataDropdownKabupaten = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        changeKecamatan() {
            this.forms.kecamatan_id = null;
            this.dataDropdownKecamatan = null;
            this.searchDropdownKecamatan('');

            this.changeKelurahan();
        },
        searchDropdownKecamatan(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.forms.kabupaten_id) {

                    if (valueEdit) {
                        this.$refs.dkecamatan.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownKecamatan = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/kecamatan-v3',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "kabupaten_id": this.forms.kabupaten_id,
                            "eo_id": this.forms.eo_id,
                        }
                    })
                    .then(res => {

                        if (purpose == "add") {
                            this.dataDropdownKecamatan = res.data.data;
                            this.loadingDropdownKecamatan = false;
                        } else if (purpose == null) {
                            this.dataDropdownKecamatan = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }
            }, 250);
        },
        changeKelurahan() {
            this.forms.kelurahan_id = null;
            this.dataDropdownKelurahan = null;
            this.searchDropdownKelurahan('');
        },
        searchDropdownKelurahan(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.forms.kecamatan_id) {

                    if (valueEdit) {
                        this.$refs.dkelurahan.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownKelurahan = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/kelurahan-v3',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "kecamatan_id": this.forms.kecamatan_id,
                            "eo_id": this.forms.eo_id,
                        }
                    })
                    .then(res => {

                        if (purpose == "add") {
                            this.dataDropdownKelurahan = res.data.data;
                            this.loadingDropdownKelurahan = false;
                        } else if (purpose == null) {
                            this.dataDropdownKelurahan = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/sampling-area/create',
                data: {
                    "cp_area": this.forms.cp_area,
                    "cp_alamat": this.forms.cp_alamat,
                    "eo_id": this.forms.eo_id,
                    "provinsi_id": this.forms.provinsi_id,
                    "kabupaten_id": this.forms.kabupaten_id,
                    "kecamatan_id": this.forms.kecamatan_id,
                    "kelurahan_id": this.forms.kelurahan_id
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data ? err.response.data.data : {});
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.cp_area = null;
            this.forms.cp_alamat = null;
            this.forms.provinsi_id = null;
            this.forms.kabupaten_id = null;
            this.forms.kecamatan_id = null;
            this.forms.kelurahan_id = null;
        },
    }
}
</script>
<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Provinsi</label>
                <Dropdown id="provinsi_id" dataKey="provinsi_id" v-model="filters.provinsi_id" :loading="loadingDropdownProvinsi"
                    :options="dataDropdownProvinsi" 
                    optionLabel="provinsi_name" optionValue="provinsi_id" placeholder="Pilih Provinsi"
                    :filter="true" :showClear="true" @filter="searchDropdownProvinsi($event, 'filter')"
                    @change="changeKabupaten()" />
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Kabupaten</label>
                <Dropdown id="kabupaten_id" dataKey="kabupaten_id" v-model="filters.kabupaten_id" :loading="loadingDropdownKabupaten"
                    :options="dataDropdownKabupaten"
                    optionLabel="kabupaten_name" optionValue="kabupaten_id" placeholder="Pilih Kabupaten"
                    :filter="true" :showClear="true" @filter="searchDropdownKabupaten($event, 'filter')" />
            </div>
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownProvinsi: false,
            loadingDropdownKabupaten: false,

            // dataDropdown
            dataDropdownProvinsi: null,
            dataDropdownKabupaten: null,

            // filter
            filters: {
                provinsi_id: null,
                kabupaten_id: null,
            },
        }
    },
    mounted() {
        this.searchDropdownProvinsi('');
    },
    created(){
    },
    watch: {
    },
    computed:{
    },
    methods: {
        // DROPDOWN
        searchDropdownProvinsi(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dprov.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownProvinsi = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/provinsi',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if (purpose == "filter") {
                        this.dataDropdownProvinsi = res.data.data;
                        this.loadingDropdownProvinsi = false;
                    } else if (purpose == null) {
                        this.dataDropdownProvinsi = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            }, 250);
        },
        changeKabupaten() {
            this.filters.kabupaten_id = null;
            this.dataDropdownKabupaten = null;
            this.searchDropdownKabupaten('');

        },
        searchDropdownKabupaten(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.filters.provinsi_id) {

                    if (valueEdit) {
                        this.$refs.dkab.filterValue = valueEdit;
                    }

                    if (purpose == "filter") {
                        this.loadingDropdownKabupaten = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/kabupaten',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "provinsi_id": this.filters.provinsi_id,
                        }
                    })
                        .then(res => {

                            if (purpose == "filter") {
                                this.dataDropdownKabupaten = res.data.data;
                                this.loadingDropdownKabupaten = false;
                            } else if (purpose == null) {
                                this.dataDropdownKabupaten = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        getDataTable(){
            this.$emit('submit');
        }
    }
}
</script>

